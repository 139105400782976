import {NgModule} from '@angular/core';
import {AuthModule, LogLevel} from 'angular-auth-oidc-client';
import {environment} from "../../environments/environment";

@NgModule({
    imports: !environment.skipAuthentication ? [
      AuthModule.forRoot({
        config: {
              authority: environment.authAuthority,
              redirectUrl: window.location.origin,
              postLogoutRedirectUri: window.location.origin,
              clientId: environment.authClientId,
              scope: 'openid profile offline_access roles email skoruba_identity_admin_api',
              responseType: 'code',
              silentRenew: true,
              useRefreshToken: true,
              renewTimeBeforeTokenExpiresInSeconds: 30,
              logLevel: environment.appEnv === "production" ? LogLevel.Warn : LogLevel.Debug,
              disableIatOffsetValidation: true,
              triggerAuthorizationResultEvent: true
        }
      })
    ] : [],
    exports: [AuthModule],
})
export class AuthConfigModule {}
