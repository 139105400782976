import {Injectable, Optional} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {EventTypes, OidcSecurityService, PublicEventsService} from "angular-auth-oidc-client";
import {LoginResponse} from "angular-auth-oidc-client/lib/login/login-response";
import {Observable, Subscription} from "rxjs";
import {filter, map} from "rxjs/operators";

@Injectable()
export class SecurityService {
  public userId: number;

  public get devOnly(): boolean {
    return environment.skipAuthentication;
  }

  constructor(@Optional() private oidcSecurityService?: OidcSecurityService,
              @Optional() private eventService?: PublicEventsService) {
    if(eventService) {
      this.eventService.registerForEvents()
        //.pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
        .subscribe((value) => {
          console.log(value);
        });
    }

    if(this.devOnly){
      if(localStorage.getItem('userId')) {
        this.userId = Number(localStorage.getItem('userId'));
      } else {
        this.userId = 2;
      }
    }
  }

  switchUser(id: number): void {
    if(this.devOnly) {
      this.userId = id;
      localStorage.setItem('userId', this.userId.toString());
      location.reload();
    }
  }

  getAccessToken(): string {
    if (!this.devOnly) {
      return this.oidcSecurityService.getAccessToken();
    } else {
      throw new Error("Attempting to get access token while not in production mode");
    }
  }

  setup(): Observable<any> {
    if (!this.devOnly) {
      return this.oidcSecurityService.checkAuth().pipe(map(((auth: LoginResponse) => {
        console.log(auth);
        if (!auth.isAuthenticated) {
          this.authorize();
        }

        if(auth.errorMessage) {
          console.error(auth.errorMessage);
        }

        return auth;
      })));
    }
  }

  authorize(): void {
    if (!this.devOnly) {
      this.oidcSecurityService.authorize();
    }
  }

  logoff(): void {
    if (!this.devOnly) {
      this.oidcSecurityService.logoff();
    }
  }

  getHttpRequestOptions(): object {
    if (!this.devOnly) {
      return {
        withCredentials: true,
        headers: {
          authorization: "Bearer " + this.oidcSecurityService.getAccessToken()
        }
      };
    } else {
      return {
        headers: {
          'debug-user-id': this.userId.toString()
        }
      };
    }
  }
}
