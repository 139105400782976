import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit} from '@angular/core';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'form-error',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {
  faExclamationTriangle = faExclamationTriangle;
  _text: string;
  _hide = true;
  _labelElement;
  _popOverError = false;
  @Input() private _errorStyling;

  @Input() set text(value) {
    if (value !== this._text) {
      this._text = value;
      this._hide = !value;
      this.cdr.detectChanges();
    }
  }

  @Input() set labelElement(value) {
    this._labelElement = value;
    this.cdr.detectChanges();
  }

  @Input() set labelElementClass(value) {
    if(!value && this._labelElement[0]) {
      this._labelElement[0].classList.remove('errorColor');
      return;
    }

    if(!this._hide && this._labelElement[0]) {
      this._labelElement[0].classList.add('errorColor');
    } else if(this._labelElement[0]) {
      this._labelElement[0].classList.remove('errorColor');
    }
    this.cdr.detectChanges();
  }

  set popOverError(value: boolean) {
    const shouldShowPopover = this.elRef?.nativeElement?.parentElement?.classList.contains('textBoxItemWrapper');
    if(shouldShowPopover) {
      this._popOverError = true;
    } else {
      this._popOverError = value;
    }
  }

  get popOverError(): boolean {
    return this._popOverError;
  }

  get errorStyling() {
    return this._errorStyling;
  }

  set errorStyling(value) {
    this._errorStyling = value;
    const sibling = this.elRef.nativeElement.previousSibling;

    if(value && sibling) {
      sibling.classList?.add('error');
    } else {
      sibling.classList?.remove('error');
    }
  }

  constructor(private cdr: ChangeDetectorRef, private elRef: ElementRef) { }

  ngOnInit(): void {}
}
