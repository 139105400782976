<div id="location-dialog" [attr.data-cy]="'location-dialog'">
  <div id="header">
    Selecteer uw locatie
  </div>

  <div id="locations" *ngIf="locations.length > 0">
    <div class="location"
         data-cy="location"
         [class.activeLocation]="data.activeLocationId === location.id"
         (click)="data.activeLocationId === location.id || selectLocation(location)"
         *ngFor="let location of locations">
      <div class="info">
        <div class="name">
          {{location.name}}
          <span class="active" *ngIf="data.activeLocationId === location.id" [attr.data-cy]="'active-location'"> - Huidige locatie</span>
        </div>
        <div class="city">{{location.address.city}}</div>
      </div>
      <fa-icon *ngIf="data.activeLocationId !== location.id" [icon]="faArrowRight"></fa-icon>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div id="footer">
    <button mat-button (click)="logout()" class="secondaryButton" *ngIf="!data.activeLocation">
      <fa-icon [icon]="faSignOut"></fa-icon>
      Uitloggen
    </button>
    <button mat-button (click)="dialogRef.close()" class="secondaryButton" *ngIf="data.activeLocation">
      Annuleren
    </button>
  </div>
</div>
