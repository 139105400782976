import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoaderService} from './core/services/loader/loader.service';
import {registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PaginatorNL_nld} from './util/paginator/paginator-nl';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MomentModule} from 'ngx-moment';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {ThemeService} from "./core/services/theme/theme.service";
import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {FormErrorModule} from "./core/directives/form-error-directive/form-error.module";
import {AuthConfigModule} from './auth/auth-config.module';
import {SecurityService} from "./core/services/security/security.service";
import {MatButtonModule} from '@angular/material/button';
import {SelectLocationModule} from './modules/shared/dialogs/select-location/select-location.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {UnauthorizedComponent} from "./auth/unauthorized/unauthorized.component";
import {ForbiddenComponent} from "./auth/forbidden/forbidden.component";
import {NgChartsModule} from 'ng2-charts';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import {Router} from '@angular/router';
import {Event} from '@sentry/angular';
import {environment} from '../environments/environment';
import {GlobalHttpInterceptorService} from "./core/interceptors/GlobaslHttpInterceptor";
import {GlobalErrorHandler} from "./core/error-handling/GlobalErrorHandler";

registerLocaleData(localeNl);

// FIXME: should make these values dynamic.
if(['staging', 'production'].indexOf(environment.appEnv) > -1) {
  Sentry.init({
    dsn: "https://47580a0abf124e2da66717ac43c6e031@o1173575.ingest.sentry.io/6268792",
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['app.staging.oogkeuring.nl', 'app.oogkeuring.nl', environment.apiUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    beforeSend(event): Event {
      return event;
    },
    tracesSampleRate: 0.25,
    debug: false,
    environment: environment.appEnv,
  });
}

export function themeFactory(themeService: ThemeService): any {
  return () => themeService.setThemeOnStart();
}

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    ForbiddenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MomentModule,
    FontAwesomeModule,
    FormErrorModule,
    AuthConfigModule,
    MatButtonModule,
    MatDialogModule,
    SelectLocationModule,
    MatSnackBarModule,
    NgChartsModule,
  ],
    providers: [
      { provide: ErrorHandler, useClass: GlobalErrorHandler },
      { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      LoaderService,
      SecurityService,
      {provide: MatPaginatorIntl, useValue: PaginatorNL_nld()},
      {provide: APP_INITIALIZER, useFactory: themeFactory, deps: [ThemeService, Sentry.TraceService], multi: true},
      {provide: MAT_DATE_LOCALE, useValue: 'nl'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(far, fas);
  }
}
