// DO NOT MAKE CHANGES TO THIS FILE. This file is automatically generated! Add custom models to the _template.ts file!
import {MatTableDataSource} from "@angular/material/table";
import {Type} from "class-transformer";
import {ChartType} from "chart.js";
import {FormControl} from "@angular/forms";

export class PaginatedResult<T> {
  items: T[];
  meta: IPaginationMeta;
  links?: IPaginationLinks
}

export interface IPaginationMeta {
  itemCount: number;
  totalItems: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface IPaginationLinks {
  first?: string;
  previous?: string;
  next?: string;
  last?: string;
}

export interface IOverviewData<T> {
  title: string;
  id: string;
  dataSource: MatTableDataSource<T>;
}

export class DayPartDate {
  date: Date;
  id: PartOfDay;
  name: string;
}

export enum PartOfDay {
  Morning = "morning",
  Afternoon = "afternoon"
}

// -- Constants -- //
export const DefaultPaginationValues = {
  PageSizeOptions: [5, 15, 25],
  PageSize: 5
}

export const DefaultPagination = {
  itemCount: 0,
  totalItems: 0,
  itemsPerPage: DefaultPaginationValues.PageSize,
  totalPages: 1,
  currentPage: 1
} as IPaginationMeta;

export class ButtonData {
  icon: string;
  text: string;
  onClick: () => void;
  url: string;
  badgeHidden: boolean;
  disabledIf?: (() => boolean) = () => false;

  constructor(args?: Partial<ButtonData>) {
    Object.assign(this, args);
  }
}

export class NewButtonData {
  active?: boolean;
  text: string;
  headerTitle: string;
  storageIndex: string;
  icon: any;
  url: string;
  onClick: Function;
  badgeHidden?: boolean;
  disabledIf?: (() => boolean) = () => false;

  constructor(args?: Partial<NewButtonData>) {
    Object.assign(this, args);
  }
}

// -- Backend errors -- //
export class HttpErrorMessage {
  field: string;
  error: string;
}

export class HttpError {
  statusCode: number;
  message: HttpErrorMessage[] | string[] = [];
  error: string;
}

export class DialogData {
  title: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
}

export class ConfirmDialogData extends DialogData {
  deletable?: boolean;
  finalizeMethod?: DropdownData[];
}

export interface BannerData {
  message: string;
  buttons: Array<{
    text: string;
    type: string;
    color?: string;
    onClick: Function;
  }>;
}

export enum GradingType {
  Dropdown,
  Number
}

export class SendSMSDialogData extends DialogData {
  recipient?: string;
  client?: any;
  recipients?: string[];
  clients?: any[];
}

export interface DropdownData {
  name: string;
  value: any;
}

export enum ThemeMode {
  DARK, LIGHT
}

// -- Helper -- //
function autoImplement<T>(): new () => T {
  return class { } as any;
}

// Buttons in Forms
export enum FormButtons {
  ConceptOptometrist = 'concept_optometrist',
  FinalizedOptometrist = 'finalized_optometrist',
  ReturnedOptometrist = 'returned_optometrist',
  SubmittedOptometrist = 'submitted_optometrist',
  GradedOptometrist = 'graded_optometrist',
  CancelledOptometrist = 'cancelled_optometrist',
  GVOOptometrist = 'gvo_optometrist',
  ReturnedDoctorOptometrist = 'returned_ophthalmologist_optometrist',
  AdditionalQuestionOptometrist = 'additional_question_optometrist',
  AdditionalQuestionAnsweredOptometrist = 'additional_question_answered_optometrist',
  GVOPlannedOptometrist = 'gvo_planned_optometrist',
  ConceptOphthalmologist = 'concept_ophthalmologist',
  FinalizedOphthalmologist = 'finalized_ophthalmologist',
  ReturnedOphthalmologist = 'returned_ophthalmologist',
  SubmittedOphthalmologist = 'submitted_ophthalmologist',
  GradedOphthalmologist = 'graded_ophthalmologist',
  CancelledOphthalmologist = 'cancelled_ophthalmologist',
  GVOOphthalmologist = 'gvo_ophthalmologist',
  ReturnedDoctorOphthalmologist = 'returned_ophthalmologist_ophthalmologist',
  AdditionalQuestionOphthalmologist = 'additional_question_ophthalmologist',
  AdditionalQuestionAnsweredOphthalmologist = 'additional_question_answered_ophthalmologist',
  GVOPlannedOphthalmologist = 'gvo_planned_ophthalmologist',
  ConceptManager = 'concept_manager',
  FinalizedManager = 'finalized_manager',
  ReturnedManager = 'returned_manager',
  SubmittedManager = 'submitted_manager',
  GradedManager = 'graded_manager',
  CancelledManager = 'cancelled_manager',
  GVOManager = 'gvo_manager',
  ReturnedDoctorManager = 'returned_ophthalmologist_manager',
  AdditionalQuestionManager = 'additional_question_manager',
  AdditionalQuestionAnsweredManager = 'additional_question_answered_manager',
  GVOPlannedManager = 'gvo_planned_manager',
  ConceptTOA = 'concept_technical_ophthalmic_assistant',
  FinalizedTOA = 'finalized_technical_ophthalmic_assistant',
  ReturnedTOA = 'returned_technical_ophthalmic_assistant',
  SubmittedTOA = 'submitted_technical_ophthalmic_assistant',
  GradedTOA = 'graded_technical_ophthalmic_assistant',
  CancelledTOA = 'cancelled_technical_ophthalmic_assistant',
  GVOTOA = 'gvo_technical_ophthalmic_assistant',
  ReturnedDoctorTOA = 'returned_ophthalmologist_technical_ophthalmic_assistant',
  AdditionalQuestionTOA = 'additional_question_technical_ophthalmic_assistant',
  AdditionalQuestionAnsweredTOA = 'additional_question_answered_technical_ophthalmic_assistant',
  GVOPlannedTOA = 'gvo_planned_technical_ophthalmic_assistant',
}

export enum FormButtonsTypes {
  Edit = 'edit',
  Finish = 'finish',
  Cancel = 'cancel',
  GVO = 'gvo',
  ReturnOptometrist = 'return_optometrist',
  SaveAsConcept = 'save_as_concept',
  ReturnOphthalmologist = 'return_ophthalmologist',
  CreateNewExam = 'create_new_exam',
  TakeOverDossier = 'take_over_dossier',
  LogData = 'log_data',
  AutoFill = 'auto_fill',
  GVOPlanned = 'gvo_planned',
  ResendPDF = 'resend_pdf',
  RetrieveReturn = 'retrieve_return'
}

export let AmountOfScans = {
  slit_lamp: {
    od: [],
    os: [],
    ods: [],
  },
  fundus: {
    od: [],
    os: [],
    ods: [],
  },
  oct_macula: {
    od: [],
    os: [],
    ods: [],
  },
  oct_papil: {
    od: [],
    os: [],
    ods: [],
  },
  visual_field: {
    od: [],
    os: [],
    ods: [],
  },
  topography: {
    od: [],
    os: [],
    ods: [],
  },
};

export enum MeetingStandardTypes {
  Group1OneYear = 'group_1_one_year',
  Group1ThreeYears = 'group_1_three_years',
  Group1FiveYears = 'group_1_five_years',
  Group1TenYears = 'group_1_ten_years',
  Group1Unlimited = 'group_1_unlimited',
  Group1NotSuitable = 'group_1_not_suitable',
  Group2OneYear = 'group_2_one_year',
  Group2ThreeYears = 'group_2_three_years',
  Group2FiveYears = 'group_2_five_years',
  Group2Unlimited = 'group_2_unlimited',
  Group2NotSuitable = 'group_2_not_suitable',
}

export enum ResearchQualityTypes {
  Bad = 'bad',
  Mediocre = 'mediocre',
  Neutral = 'neutral',
  Good = 'good',
  VeryGood = 'very_good',
  NotSuitable = 'not_suitable'
}

export enum DateTimeNotations {
  DefaultDateNotation = 'DD-MM-YYYY',
  DefaultTimeNotation = 'HH:mm',
  DefaultCombinedNotation = 'DD-MM-YYYY | HH:mm'
}

export interface TableColumns {
  definition: string;
  headerText: string;
  key: string;
  sortHeader?: string;
  actions?: any;
}

export interface WidgetDropdowns {
  title: string;
  data: WidgetDropdownData[];
  type: string;
  canBeNull?: boolean;
  multiple?: boolean;
}

export interface WidgetDropdownData {
  name: string;
  value: string;
}

export interface WidgetRankings {
  title: string;
  entries: RankingEntries[];
}

export interface RankingEntries {
  number: string | number;
  name: string;
  amount: number;
  moved?: {direction: string};
}

export interface WidgetData {
  name: string;
  type: ChartType;
  showPercentages: boolean;
  chartData: Array<{
    data: Array<number>;
    label: string;
  }>;
  tooltipData: { data: any, label: string, hasData: boolean }[];
  labels: [];
  colorScheme: string;
  small: {
    show: boolean;
    icon: string;
    amount: number;
    heading: string
  };
  dropdowns: Array<{
    label: string;
    data: Array<any>;
    control: FormControl;
  }>;
  timePeriod: string;
  currentPage: number;
  maxPage: number;
  year: string;
  from: Date;
  to: Date;
  maxYAxisValue?: number;
}

export class CompanyLocation {
    id: number;
    name: string;
    email: string;
    phone: string;
    @Type(() => Address)
    address: Address;
    @Type(() => Company)
    company: Company;
    companyId: number;
    amountOfUsers: number;
    type: LocationType;
    cocNumber: string;
    vatNumber: string;
    iban: string;
    @Type(() => User)
    contactPerson: User;
    contactPersonId: number;
    @Type(() => User)
    assignedDoctor: User;
    assignedDoctorId: number;
}
type TCreateCompanyLocation = Omit<CompanyLocation, 'id' >;
export class CreateCompanyLocation extends autoImplement<TCreateCompanyLocation>() {
}
type TUpdateCompanyLocation = Partial<Omit<CompanyLocation, 'id' >>;
export class UpdateCompanyLocation extends autoImplement<TUpdateCompanyLocation>() {
}
export class Company {
    id: number;
    name: string;
    email: string;
    phone: string;
    @Type(() => Address)
    address: Address;
    @Type(() => CompanyLocation)
    locations: CompanyLocation[];
    cocNumber: string;
    vatNumber: string;
    iban: string;
    @Type(() => User)
    contactPerson: User;
    contactPersonId: number;
}
type TCreateCompany = Omit<Company, 'id' >;
export class CreateCompany extends autoImplement<TCreateCompany>() {
}
type TUpdateCompany = Partial<Omit<Company, 'id' >>;
export class UpdateCompany extends autoImplement<TUpdateCompany>() {
}
export class Consult {
    id: number;
    createdAt: Date;
    updatedAt: Date;
    @Type(() => Dossier)
    dossier: Dossier;
    dossierId: number;
    dossierType: DossierType;
    type: ConsultType;
    data: Form;
    @Type(() => CompanyLocation)
    location: CompanyLocation;
    locationId: number;
    dateCompleted: Date;
}
type TCreateConsult = Omit<Consult, 'id' >;
export class CreateConsult extends autoImplement<TCreateConsult>() {
}
export abstract class Form {
}
export class MedicalIndicationCTLOphthalmologistForm {
}
export abstract class OphthalmologistForm {
}
export class Rijges095OphthalmologistForm {
}
export class Rijges095ToaOphthalmologistForm {
}
export class Rijges107OphthalmologistForm {
    visualAbnormalities: string;
    visualAbnormalitiesExplanation: string;
    disturbedDarkAdaptation: string;
    disturbedDarkAdaptationExplanation: string;
    abnormalitiesFound: string;
    abnormality_cataract: any[];
    abnormality_pseudofakie: any[];
    abnormality_glaucoom: any[];
    abnormality_exudative: any[];
    abnormality_dry_macula: any[];
    abnormality_drp: any[];
    abnormality_amblyopia: any[];
    abnormality_nystagmus: string;
    nystagmusType: string;
    nystagmusFoundExplanation: string;
    abnormalitiesFoundExplanation: string;
    relevantCoMobidity: string;
    relevantCoMobidityAdditional: string;
    meetingStandard: any[];
    spokenToClientByPhone: string;
    notSpokenToReason: string;
    spokenToClientByPhoneExplanation: string;
    researchQuality: any[];
    researchQualityExplanation: string;
}
export class Rijges107ToaOphthalmologistForm {
}
export class MedicalIndicationCTLOptometristForm {
}
export abstract class OptometristForm {
}
export class Rijges095OptometristForm {
}
export class Rijges095ToaOptometristForm {
}
export class Rijges107OptometristForm {
    haszdNumber: boolean;
    zdNumber: string;
    zdNumberBlurred: string;
    referralReason: string;
    clientHasHadTreatment: string;
    treatmentReason: boolean[];
    treatmentReasonExplanation: string;
    otherRelevantHistory: boolean[];
    otherRelevantHistoryExplanation: string;
    confrontationTestDonders: string;
    confrontationTestDondersFirst: boolean;
    confrontationTestDondersSecond: boolean;
    confrontationTestDondersExplanation: string;
    clientHasLicense: boolean;
    licenseType: any[];
    ownCorrection: boolean[];
    refractions: string[][];
    visus: any[][];
    visus_ZEC: string[][];
    visus_MEC: string[][];
    visus_MOC: string[][];
    lensType_OD: string;
    OD_fakie: string;
    OS_fakie: string;
    lensType_OS: string;
    OS_pseudofakie: string;
    OD_pseudofakie: string;
    spleetLamp_OD: string;
    spleetLamp_OS: string;
    fundoscopie_OD: string;
    fundoscopie_OS: string;
    diplopia: string;
    diplopiaHelp: string;
    diplopiaExplanation: string;
    diplopiaDisruptive: string;
    amblyopia: string;
    amblyopiaWhichEye: boolean[];
    amblyopiaExplanation: string; // FIXME: this is actually nystagmusExplanation
    nystagmus: string;
    nystagmusType: string;
    newglasses: string;
    additional: string;
    spokenAboutRights: boolean;
    rightsShareCBR: boolean;
    scansAndImages: any[][];
}
export class Rijges107ToaOptometristForm {
}
type TUpdateConsult = Partial<Omit<Consult, 'id'| 'dossierId'| 'dossier'| 'location'| 'locationId'| 'type' >>;
export class UpdateConsult extends autoImplement<TUpdateConsult>() {
}
type TCreateCronJobData = Omit<CronJobData, 'id' >;
export class CreateCronJobData extends autoImplement<TCreateCronJobData>() {
}
export class CronJobData {
    id: number;
    type: CronJobType;
    data: any;
    @Type(() => Date)
    @Type(() => Date)
    scheduledFor: Date;
}
type TUpdateCronJobData = Partial<Omit<CronJobData, 'id' >>;
export class UpdateCronJobData extends autoImplement<TUpdateCronJobData>() {
}
type TCreateDossier = Omit<Dossier, 'id' >;
export class CreateDossier extends autoImplement<TCreateDossier>() {
}
export class DossierUpdate {
    message: string;
    status: DossierStatus;
    reasonForReturn: DossierStatus;
    finalizeMethod: FinalizeMethod;
    zdNumberOption: FinalizeZdNumberOptions;
    sentByPostExplanation: string;
}
export class Dossier {
    id: number;
    number: string;
    createdAt: Date;
    updatedAt: Date;
    @Type(() => Patient)
    patient: Patient;
    patientId: number;
    @Type(() => Consult)
    consults: Consult[];
    type: DossierType;
    slug: DossierSlug;
    status: DossierStatus;
    finalizeMethod: FinalizeMethod;
    finalizeMethodNote: string;
    @Type(() => GeneralPractitioner)
    generalPractitioner: GeneralPractitioner;
    @Type(() => User)
    owner: User;
    ownerId: number;
    @Type(() => User)
    assignedDoctor: User;
    assignedDoctorId: number;
    @Type(() => User)
    createdBy: User;
    createdById: number;
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    externalStorageId: string;
    externalStorageType: RemoteDocProtocol;
    @Type(() => CompanyLocation)
    location: CompanyLocation;
    locationId: number;
    @Type(() => Memo)
    memos: Memo[];
    @Type(() => TimelineItem)
    timelineItems: TimelineItem[];
}
type TUpdateDossier = Partial<Omit<Dossier, 'id' >>;
export class UpdateDossier extends autoImplement<TUpdateDossier>() {
}
export class VerifyDossier {
    code: string;
}
type TCreateGeneralPractitioner = Omit<GeneralPractitioner, 'id' >;
export class CreateGeneralPractitioner extends autoImplement<TCreateGeneralPractitioner>() {
}
export class GeneralPractitioner {
    id: number;
    name: string;
    agbCode: string;
    phone: string;
    email: string;
    @Type(() => Address)
    address: Address;
}
type TUpdateGeneralPractitioner = Partial<Omit<GeneralPractitioner, 'id' >>;
export class UpdateGeneralPractitioner extends autoImplement<TUpdateGeneralPractitioner>() {
}
export class Address {
    postalCode: string;
    houseNr: string;
    houseNrSuffix: string;
    city: string;
    streetName: string;
    country: string;
    municipality: string;
}
type TCreateMemo = Omit<Memo, 'id'| 'createdAt'>;
export class CreateMemo extends autoImplement<TCreateMemo>() {
}
export class Memo {
    id: number;
    createdAt: Date;
    @Type(() => User)
    createdBy: User;
    content: string;
    type: MemoType;
}
export class Name {
    initials: string;
    firstName: string;
    middleName: string;
    lastName: string;
}
export class Graph {
}
export class OverviewDossiersKPI {
    topFive: any[];
    lowestFive: any[];
    graph: { labels: string[], data: any[] };
    perType: any;
}
export class PieChart {
}
type TCreateMessage = Omit<Message, 'id'| 'recipient'| 'createdBy' >;
export class CreateMessage extends autoImplement<TCreateMessage>() {
}
export class MessageConversation {
}
export class MessagePreview {
    displayName: string;
    senderName: string;
    senderId: number;
    recipientId: number;
    recipientName: string;
    patientName: string;
    latestMessage: string;
    dateLatestMessage: Date;
    dossierId: number;
    dossierNumber: string;
    locationId: number;
    countOfMessages: number;
    countOfUnreadMessages: number;
}
export class Message {
    id: number;
    createdAt: Date;
    @Type(() => User)
    createdBy: User;
    createdById: number;
    @Type(() => User)
    recipient: User;
    recipientId: number;
    @Type(() => Dossier)
    dossier: Dossier;
    dossierId: number;
    locationId: number;
    @Type(() => Patient)
    patient: Patient;
    patientId: number;
    message: string;
    isRead: boolean;
    readAt: Date;
    type: MessageType;
}
type TUpdateMessage = Partial<Omit<Message, 'id' >>;
export class UpdateMessage extends autoImplement<TUpdateMessage>() {
}
type TCreatePatient = Omit<Patient, 'id' >;
export class CreatePatient extends autoImplement<TCreatePatient>() {
}
export class Patient {
    id: number;
    bsn: string;
    idChecked: boolean;
    initials: string;
    firstName: string;
    middleName: string;
    lastName: string;
    partnerMiddleName: string;
    partnerName: string;
    email: string;
    phone: string;
    dateOfBirth: Date;
    gender: Gender;
    @Type(() => Address)
    address: Address;
}
type TUpdatePatient = Partial<Omit<Patient, 'id' >>;
export class UpdatePatient extends autoImplement<TUpdatePatient>() {
}
type TCreateRemoteDocument = Omit<RemoteDocument, 'id' >;
export class CreateRemoteDocument extends autoImplement<TCreateRemoteDocument>() {
}
export class DocUpload {
    type: string;
    name: string;
    file?: any;
    eyeScanType: EyeScanType;
    eyeScanSide: EyeScanSide;
}
export class RemoteDocument {
    id: number;
    createdAt: Date;
    name: string;
    uri: string;
    thumbnailUri?: string;
    thumbnailMimeType?: string;
    thumbnailData?: string;
    mimeType: string;
    protocol: string;
    type: RemoteDocType;
    eyeScanType: EyeScanType;
    eyeScanSide: EyeScanSide;
    @Type(() => User)
    createdBy: User;
    @Type(() => Memo)
    memos: Memo[];
}
type TUpdateRemoteDocument = Partial<Omit<RemoteDocument, 'id' >>;
export class UpdateRemoteDocument extends autoImplement<TUpdateRemoteDocument>() {
}
type TCreateTimelineItem = Omit<TimelineItem, 'id' >;
export class CreateTimelineItem extends autoImplement<TCreateTimelineItem>() {
}
export class TimelineItem {
    id: number;
    previousStatus: DossierStatus;
    newStatus: DossierStatus;
    @Type(() => Dossier)
    dossier: Dossier;
    dossierId: number;
    @Type(() => User)
    createdBy: User;
    createdById: number;
    @Type(() => User)
    nextOwner: User;
    nextOwnerId: number;
    createdAt: Date;
}
type TUpdateTimelineItem = Partial<Omit<TimelineItem, 'id' >>;
export class UpdateTimelineItem extends autoImplement<TUpdateTimelineItem>() {
}
type TCreateUser = Omit<User, 'id'| 'authId'| 'locations'| 'isAbsent' >;
export class CreateUser extends autoImplement<TCreateUser>() {
    authId: string;
    locations?: { id: number }[];
    noSSOUser?: boolean;
}
export class SetAbsent {
    substituteId: number;
    @Type(() => Date)
    @Type(() => Date)
    absentUntil: Date;
}
type TUpdateUser = Partial<Omit<User, 'id'| 'isAbsent' >>;
export class UpdateUser extends autoImplement<TUpdateUser>() {
}
export class User {
    id: number;
    authId: string;
    email: string;
    phoneNumber: string;
    displayName: string;
    salutation: string;
    initials: string;
    firstName: string;
    middleName: string;
    lastName: string;
    formalName?: string;
    kpNumber?: string;
    bigNumber?: string;
    agbCode?: string;
    role: UserRole;
    gender: Gender;
    status: UserStatus;
    @Type(() => CompanyLocation)
    locations?: CompanyLocation[];
    @Type(() => CompanyLocation)
    activeLocation?: CompanyLocation;
    activeLocationId?: number;
    lastLogin: Date;
    validationKeysTurnedOff: string[];
    isAbsent: boolean;
    absenceRevertCronjob: CronJobData;
    notificationChannel: NotificationChannel;
}

export enum UserRole {
    Generic = "generic",
    Admin = "admin",
    Optometrist = "optometrist",
    Ophthalmologist = "ophthalmologist",
    Manager = "manager",
    ContactPerson = "contact_person",
    TOA = "technical_ophthalmic_assistant"
}

export enum RemoteDocProtocol {
    Local = 'local',
    GoogleDrive = 'google-drive'
}

export enum Gender {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

export enum UserStatus {
    Active = 'active',
    Inactive = 'inactive',
    Absent = 'absent',
}

export enum DossierType {
    Rijges107 = 'rijges107',
    Rijges095 = 'rijges095',
    MedicalIndicationCTL = 'medischeindicatiectl',
    Rijges107TOA = 'rijges107toa',
    Rijges095TOA = 'rijges095toa',
}

export enum DossierSlug {
    Rijges107 = 'rijges-107',
    Rijges095 = 'rijges-095',
    MedicalIndicationCTL = 'medische-indicatie-ctl',
    Rijges107TOA = 'rijges-107-toa',
    Rijges095TOA = 'rijges-095-toa',
}

export enum ConsultType {
    optometrist = 'optometrist',
    ophthalmologist = 'ophthalmologist',
}

export enum DossierStatus {
    Concept = 'concept', // concept
    Submitted = 'submitted', // in behandeling
    Graded = 'graded', // gekeurd
    Returned = 'returned', // retour concept / optometrist
    Finalized = 'finalized', // afgerond
    Cancelled = 'cancelled', // afgebroken / geannuleerd
    GVO = 'gvo', // gvo aangevraagd
    GVO_Planned = 'gvo_planned', // gvo gepland
    Returned_Ophthalmologist = 'returned_ophthalmologist', // retour oogarts
    Additional_Question = 'additional_question', // aanvullende vraag
    Additional_Question_Answered = 'additional_question_answered' // aanvullende vraag beantwoord
}

export enum FinalizeMethod {
    ZorgDomein = 'zorgdomein', // ingediend zd
    Letter = 'letter', // ingediend post
    Administrative = 'administrative' // finalized without actually submitting the dossier - used for administrative purposes.
}

export enum FinalizeZdNumberOptions {
    ZdnumberInvalid = 'zdnumber_invalid',
    ZdnumberCutOff = 'zdnumber_cutoff',
    ZdNumberOther = 'zdnumber_other'
}

export enum RemoteDocType {
    Generic = 'generic',
    EyeScan = 'eye_scan'
}

export enum EyeScanType {
    Fundus = 'fundus',
    SlitLamp = 'slit_lamp',
    OCT_Macula = 'oct_macula',
    OCT_Papil = 'oct_papil',
    VisualField = 'visual_field',
    Topography = 'topography'
}

export enum EyeScanSide {
    OD = 'od',
    OS = 'os',
    ODS = 'ods'
}

export enum MessageType {
    Generic = 'generic',
    GVO = 'gvo',
    Return = 'Return'
}

export enum MemoType {
    Note = 'note'
}

export enum LocationType {
    OpticiansShop = 'opticians_shop',
    EyeClinic = 'eye_clinic',
}

export interface ODOSObject {
    OD: string;
    OS: string;
}

// When adding a new type, update the table in CronJobExecuteService.executeJobs
export enum CronJobType {
    AbsenceDossierTakeover = 'absence_dossier_takeover'
}

export enum NotificationChannel {
    SMS = 'sms',
    Mail = 'mail'
}
