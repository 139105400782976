import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import * as moment from "moment";
import {CreateUser, Dossier, SetAbsent, UpdateUser, User} from '../../../models';
import {plainToClass} from "class-transformer";
import {environment} from "../../../../../environments/environment";
import {SecurityService} from "../../security/security.service";
import {BaseRestAPIService} from '../../base-rest-api/base-rest-api.service';
import {filter, take} from "rxjs/operators";
import * as Sentry from "@sentry/browser";

moment.locale('nl');

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseRestAPIService<User, CreateUser, UpdateUser> {
  activeUser: User;
  userEmitter = new ReplaySubject<User>(1);

  constructor(http: HttpClient,
              securityService: SecurityService) {
    super(http,
      `${environment.apiUrl}/user`,
      input => plainToClass(User,
        input
      ), securityService);
  }

  async whoAmI(reset = false): Promise<User> {
    if(!this.activeUser || reset) {
      this.activeUser = await this.http.get<User>(`${environment.apiUrl}/user/me`, this.securityService.getHttpRequestOptions()).toPromise();
      this.userEmitter.next(this.activeUser);
      Sentry.setUser({
        id: this.activeUser.id.toString(),
        email: this.activeUser.email,
        username: this.activeUser.displayName
      });
      return this.activeUser;
    }

    return this.userEmitter.pipe(take(1)).toPromise();
  }

  async switchLocation(locationId: number): Promise<any> {
    return await this.http.post(`${environment.apiUrl}/user/me/switch-location/${locationId}`, {}, this.securityService.getHttpRequestOptions()).toPromise();
  }

  setAbsent(userId: number, dto: SetAbsent): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/user/${userId}/set-absent`,
      dto,
      this.securityService.getHttpRequestOptions()
    );
  }

  revertAbsence(userId: number): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/user/${userId}/revert-absence`,
      {},
      this.securityService.getHttpRequestOptions()
    );
  }
}
