import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectLocationComponent} from './select-location.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
  declarations: [
    SelectLocationComponent
  ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        MatButtonModule,
        MatDividerModule
    ],
  exports: [SelectLocationComponent],
})

export class SelectLocationModule { }
