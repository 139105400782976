import {NgModule} from '@angular/core';
import {FormErrorHandlingDirective} from './form-error-handling.directive';
import {FormErrorContainerDirective} from './form-error-container.directive';
import {FormErrorSubmitDirective} from './form-error-submit.directive';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormErrorComponent} from '../../../modules/shared/form-error/form-error.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MdePopoverModule} from '@material-extended/mde';
import {CommonModule} from '@angular/common';
import {MatCardModule} from '@angular/material/card';

@NgModule({
  declarations: [
    FormErrorHandlingDirective,
    FormErrorContainerDirective,
    FormErrorSubmitDirective,
    FormErrorComponent
  ],
  entryComponents: [
    FormErrorComponent
  ],
  imports: [
    MatFormFieldModule,
    FontAwesomeModule,
    MdePopoverModule,
    CommonModule,
    MatCardModule
  ],
  exports: [
    FormErrorHandlingDirective,
    FormErrorSubmitDirective,
    FormErrorContainerDirective,
    FormErrorComponent
  ]
})
export class FormErrorModule {
}
