import {ErrorHandler, Injectable} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import * as Sentry from "@sentry/browser";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
  ) {}

  handleError(error: any): void {
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection; // get the error object
    }

    Sentry.captureException(error);
  }
}
