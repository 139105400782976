import {Component, OnInit} from '@angular/core';
import {environment} from "../environments/environment";
import {SecurityService} from "./core/services/security/security.service";
import {UserService} from "./core/services/vorteq/user/user.service";
import {SelectLocationComponent} from './modules/shared/dialogs/select-location/select-location.component';
import {User} from './core/models';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from "@angular/material/snack-bar";
import {LoginResponse} from "angular-auth-oidc-client/lib/login/login-response";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(public securityService: SecurityService,
              public userService: UserService,
              private backendSnackbar: MatSnackBar,
              private dialog: MatDialog) {}

  me: User;

  async ngOnInit(): Promise<void> {
    if (!environment.skipAuthentication) {
      // TODO: well, see below...
      // so this seems to work properly now, however,
      // I'd prefer to just use the built-in auth guards (https://nice-hill-002425310.azurestaticapps.net/docs/documentation/auto-login)
      // it's much cleaner and gives some easier ways in which to manage redirect and saving of route before login.
      this.securityService.setup().subscribe((auth: LoginResponse) => {
        if(auth.isAuthenticated) {
          sessionStorage.removeItem('rl');
          this.loadUser();
        }
      }, err => {
        console.log("Encountered error while setting up SecurityService: ", err);
        // we no longer check for stale error, just attempt a fresh client challenge in any case.
        // if (err.indexOf('could not find matching config for state') > -1) {
        console.log("Error found, attempting reload of page...");

        const reloaded = parseInt(sessionStorage.getItem("rl"), 10) || 0;
        if (reloaded < 1) {
          sessionStorage.setItem("rl", (reloaded + 1).toString());
          window.location.replace(window.location.origin);
        } else {
          console.log("Redirect to generic error page");
          window.location.replace(environment.authAuthority + '/home/error');
        }
        // }
      });
    } else {
      this.loadUser();
    }
  }

  async loadUser(): Promise<void> {
    await this.userService.whoAmI().then((user) => {
      this.me = user;
      this.checkActiveLocation();
    });
  }

  checkActiveLocation(): void {
    if(!this.me.activeLocation) {
      this.openLocationSelectDialog();
    }
  }

  openLocationSelectDialog(): void {
    const dialogRef = this.dialog.open(SelectLocationComponent, {data: this.me,  panelClass: 'select-location-dialog-container', disableClose: true });

    dialogRef.afterClosed().subscribe(async location => {
      if (location) {
        this.me.activeLocation = location;
        this.userService.switchLocation(location.id).then(result => {
          this.backendSnackbar.open(`Locatie gewijzigd naar ${location.name}`, 'Sluiten', {duration: 3000});

          // FIXME, make it a silent reload or something.
          setTimeout(() => window.location.reload(), 500);
          // console.log(result);
        });
      }
    });
  }
}
