import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {SortDirection} from "@angular/material/sort/sort-direction";
import {PaginatedResult} from "../../models";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {SecurityService} from "../security/security.service";

moment.locale('nl');

export class APIFilterOption<T> {
  constructor(public key: (keyof  T | string),
              public value: string | number | string[] | number[],
              public exact = false) {

  }
}

export class APISortOptions<T> {
  //active: (keyof T & string); // TODO: interoperability with mat-angular Sort class would be great, also for type checking.
  active: string;
  direction: SortDirection;
}

export abstract class BaseRestAPIService<ReadDTO extends { id }, CreateDTO, UpdateDTO> {
  protected constructor(protected http: HttpClient,
                        protected apiUrl: string,
                        protected mapper: (input: any) => ReadDTO,
                        protected securityService: SecurityService) {
  }

  get(id: number): Observable<ReadDTO> {
    return this.http.get<ReadDTO>(
      `${this.apiUrl}/${id}`, this.securityService.getHttpRequestOptions()
    );
  }

  getAll(pageIndex = 1,
         pageSize = 10,
         search: string = null,
         filter: APIFilterOption<ReadDTO>[] = [],
         sort: APISortOptions<ReadDTO> = {active: 'id', direction: 'asc'}): Observable<PaginatedResult<ReadDTO>> {

    const filterOptions = {};
    for (const item of filter) {
      if (item.value !== null && item.value !== '' || (item.value !== null && Array.isArray(item.value) && item.value.length > 0)) {
        let str = item.value;
        if(item.exact) {
          str = '!' + item.value;
        }
        filterOptions[item.key as string] = str;
      }
    }

    if (search && search !== '') {
      filterOptions['search'] = search;
    }

    const sortParam = {};
    if (sort) {
      sortParam[sort.active as string] = sort.direction;
    }

    return this.http
      .get<PaginatedResult<ReadDTO>>(
        `${this.apiUrl}?page=${pageIndex}&limit=${pageSize}
            &filter=${JSON.stringify(filterOptions)}&sort=${JSON.stringify(sortParam)}`,
        this.securityService.getHttpRequestOptions()
      )
      .pipe(
        map(response => {
          response.items = response.items.map(plain =>
            this.mapper(plain)
          );
          return response;
        })
      );
  }

  create(dto: CreateDTO): Observable<ReadDTO> {
    return this.http.post<ReadDTO>(
      this.apiUrl,
      dto,
      this.securityService.getHttpRequestOptions()
    );
  }

  update(id: number, dto: UpdateDTO): Observable<ReadDTO>  {
    return this.http.patch<ReadDTO>(
      `${this.apiUrl}/${id}`,
      dto,
      this.securityService.getHttpRequestOptions()
    );
  }

  delete(id: number): Observable<any>  {
    return this.http.delete<ReadDTO>(
      `${this.apiUrl}/${id}`,
      this.securityService.getHttpRequestOptions()
    );
  }
}

