import {Component, Inject, OnInit} from '@angular/core';
import {faArrowRight, faSignOut} from '@fortawesome/pro-regular-svg-icons';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../../core/models';
import {SecurityService} from '../../../../core/services/security/security.service';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss']
})
export class SelectLocationComponent implements OnInit {
  faArrowRight = faArrowRight;
  faSignOut = faSignOut;
  locations;

  constructor(public dialogRef: MatDialogRef<SelectLocationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: User,
              private securityService: SecurityService) { }

  ngOnInit(): void {
    this.locations = this.data.locations;
  }

  selectLocation(location): void {
    this.dialogRef.close(location);
  }

  async logout(): Promise<void> {
    this.securityService.logoff();
    this.dialogRef.close();
    setTimeout(() => window.location.reload(), 1000);
  }
}
