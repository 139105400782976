import {InjectionToken} from '@angular/core';
import {patternToError} from '../../../util/validators/validatorHelper';
import {FormConstants} from '../../../util/forms/form-helpers';

export const defaultErrors = {
  required: (error) => `Dit veld is verplicht.`,
  minlength: ({ requiredLength, actualLength }) => `Expect ${requiredLength} but got ${actualLength}`,
  pattern: (error) => patternToError(error.requiredPattern),
  iban: (error) => 'Incorrecte IBAN. Voorbeeld: NL05 RABO 1234 1234 00',
  cocNumberInvalid: (error) => 'Dit KVK nummer is ongeldig. Het moet 8 cijfers bevatten.',
  vatNumberInvalid: (error) => 'Dit NTW nummer is ongeldig. Voorbeeld: NL123456789B01.',
  bsn11: (error) => 'Dit BSN-nummer is niet geldig',
  checkedAge: (error) => 'Client moet tussen de 15 en 120 jaar zijn.',
  missingRequiredScans: (error) => 'Verplichte scans missen: minimaal OD én OS, of één ODS.',
  requireCheckboxesToBeChecked: (error) => 'Er moet minimaal één optie aangevinkt zijn.',
  noLicenseOptionSelected: (error) => 'Er is geen rijbewijs categorie geselecteerd.',
  hasToBeTrue: (error) => 'Dit veld is verplicht.',
  noMinOneSelected: (error) => 'Er moet minimaal één optie aangevinkt zijn.',
  zdNumberContainsPattern: (error) => 'Het ZD nummer bevat een ongeldig patroon.',
  zdNumberLengthNotCorrect: (error) => `Het ZD nummer is niet compleet.`,
  alsoFillRefractionC: (error) => `Refractie C is niet ingevuld. (Voer een getal in tussen ${FormConstants.REFRACTION_C_HIGH_TEXT} en ${FormConstants.REFRACTION_C_LOW_THRESHOLD})`,
  alsoFillRefractionAS: (error) => `Refractie AS is niet ingevuld. (Voer een getal in tussen ${FormConstants.REFRACTION_AS_LOW_THRESHOLD} en ${FormConstants.REFRACTION_AS_HIGH_THRESHOLD})`,
  visusIncorrect: (error) => ({
    text: `Voer een getal in tussen ${FormConstants.VISUS_LOW_THRESHOLD} en ${FormConstants.VISUS_HIGH_THRESHOLD}.00`,
    showPopover: true
  }),
  refractionASIncorrect: (error) => ({
    text: `Voer een getal in tussen ${FormConstants.REFRACTION_AS_LOW_THRESHOLD} en ${FormConstants.REFRACTION_AS_HIGH_THRESHOLD}`,
    showPopover: true
  }),
  refractionSIncorrect: (error) => ({
    text: `Voer een getal in tussen ${FormConstants.REFRACTION_S_LOW_THRESHOLD} en ${FormConstants.REFRACTION_S_HIGH_THRESHOLD}`,
    showPopover: true
  }),
  refractionCIncorrect: (error) => ({
    text: `Voer een getal in tussen ${FormConstants.REFRACTION_C_HIGH_TEXT} en ${FormConstants.REFRACTION_C_LOW_THRESHOLD}`,
    showPopover: true
  }),
  refractionCAndAsIncorrect: (error) => ({
    text: `C: Voer een getal in tussen ${FormConstants.REFRACTION_C_HIGH_TEXT} en ${FormConstants.REFRACTION_C_LOW_THRESHOLD}. AS: Voer een getal in tussen ${FormConstants.REFRACTION_AS_LOW_THRESHOLD} en ${FormConstants.REFRACTION_AS_HIGH_THRESHOLD}`,
    showPopover: true
  }),
  decimalIncorrect: (error) => ({
    text: 'Moet een cijfer met twee decimalen bevatten. Voorbeeld: 1.35',
    showPopover: true
  }),
};

export const patternErrors = {
  // pattern(['"^[a-z0-9_-]{8,15}$"; ']): (error) => 'Veld mag alleen letters bevatten',
  // pattern: ({lettersOnlyPattern}) => 'Veld mag alleen letters bevatten',
};

export const serverErrors = {

};

export const PATTERN_ERRORS = new InjectionToken('PATTERN_ERRORS', {
  providedIn: 'root',
  factory: () => patternErrors
});

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});


