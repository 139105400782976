import {DossierType} from '../app/core/models';

export const environment = {
  production: true,
  skipAuthentication: false,
  skipSMSChecks: true,
  apiUrl: 'https://api.staging.oogkeuring.nl/v1',
  authAuthority: 'https://sso.staging.oogkeuring.nl',
  authClientId: 'keuringen-frontend',
  appEnv: 'staging',
  enabledSidebarButtons: [
    {key: 0, value: DossierType.Rijges107},
    {key: 1, value: DossierType.Rijges107TOA},
    {key: 2, value: DossierType.Rijges095},
    {key: 3, value: DossierType.Rijges095TOA},
    {key: 4, value: DossierType.MedicalIndicationCTL}
  ]
};
