<ng-container *ngIf="!popOverError else popoverError">
  <mat-error [class.hide]="_hide">{{_text}}</mat-error>
  <fa-icon class="errorIcon" [class.hide]="_hide" [icon]="faExclamationTriangle"></fa-icon>
</ng-container>

<ng-template #popoverError>
  <fa-icon
    class="errorIcon popOver"
     [class.hide]="_hide"
     [icon]="faExclamationTriangle"
     [mdePopoverTriggerFor]="appPopover"
     mdePopoverTriggerOn="hover"
  ></fa-icon>

  <mde-popover
    #appPopover="mdePopover"
    [mdePopoverOverlapTrigger]="false">
    <mat-card style="max-width: 40em" class="defaultToolTip">
      <mat-card-content>
        {{_text}}
      </mat-card-content>
    </mat-card>
  </mde-popover>
</ng-template>
