import {FormArray, FormGroup, ValidationErrors} from "@angular/forms";

export default class FormHelpers {
  public static findErrorsRecursive(
    formToInvestigate: FormGroup | FormArray
  ): ValidationErrors[] {
    const errors: ValidationErrors[] = [];
    const recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        if (control?.errors) {
          errors.push({key: field, error: control.errors});
        }
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }
      });
    };
    recursiveFunc(formToInvestigate);
    return errors;
  }
}

// TODO: Move this to constants folder
export class FormConstants {
  public static VISUS_LOW_THRESHOLD = 0;
  public static VISUS_HIGH_THRESHOLD = 2;
  public static REFRACTION_AS_LOW_THRESHOLD = 0;
  public static REFRACTION_AS_HIGH_THRESHOLD = 179;
  public static REFRACTION_S_LOW_THRESHOLD = -20;
  public static REFRACTION_S_HIGH_THRESHOLD = 15;
  public static REFRACTION_C_LOW_THRESHOLD = 0;
  public static REFRACTION_C_HIGH_THRESHOLD = -9.99;
  public static REFRACTION_C_HIGH_TEXT = -10;
}
